import { PageLayout } from "../components/page-layout";

export const PrivacyPage: React.FC = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Privacy
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>
            </span>
            <span>
            </span>
          </p>
        </div>
      </div>
    </PageLayout>
  );
};
