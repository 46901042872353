import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

// const redirect_uri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  // https://manage.auth0.com/dashboard/jp/diveintohacking/universal-login/customizations-new
  // https://auth0.com/docs/libraries/auth0-single-page-app-sdk#login-and-get-user-info
  const handleLogin = async () => {
    // https://auth0.github.io/auth0-spa-js/interfaces/RedirectLoginOptions.html
    await loginWithRedirect({
      // https://auth0.github.io/auth0-react/types/AppState.html
      appState: {
        returnTo: "/settings",
      },
      // https://auth0.github.io/auth0-react/interfaces/AuthorizationParams.html
      authorizationParams: {
        prompt: "login",
        // redirect_uri
      }
    });
  };

  return (
    <button className="button__login" onClick={handleLogin}>
      Log In
    </button>
  );
};
