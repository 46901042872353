import { Auth0Provider, AppState } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

const domain = 'neh.jp.auth0.com';
const clientId = 'RT1YjFYXuFV91R7p52pJbpBR2hoI0VIL';

// 下記のaudienceは、Auth0のAPIのIdentifierを指定している。
// https://manage.auth0.com/dashboard/jp/neh/apis/6736a1756a621bc7e503da42/settings
const audience = 'https://neh.app/';
const redirect_uri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

export const Auth0ProviderWithNavigate = ({
  children,
}: PropsWithChildren<Auth0ProviderWithNavigateProps>): JSX.Element | null => {
  const navigate = useNavigate();


  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!redirect_uri) return null;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
