// src/pages/email-not-verified-page.tsx

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";

export const EmailNotVerifiedPage: React.FC = () => {
  const { user } = useAuth0();

  if (!user) return null;

  return (
    <PageLayout>
      <div className="auth0-features">
        <h1 className="neh-features__title">Email Not Verified</h1>

        <div className="content__body">
          <div className="profile-grid">
            <div className="profile__header">
              <div className="profile__headline">
                <div className="profile__description">
                  {
                    /* お客様の email address ({user.email}) 宛に、email verification のためのメールをお送りしております。お手数ですが、メールボックスをご確認の上、email verification を完了していただき、再度、 <a href="https://neh.app/">Neh</a> へお越しくださいませ。 */
                  }
                  We have sent an email to your email address (<code style={{ color: 'white' }}>{user.email}</code>) for the purpose of email verification. We kindly ask you to check your inbox and complete the email verification process. Once verified, we would be delighted to welcome you back to <a href="https://neh.app/">Neh</a>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
