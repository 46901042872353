// src/services/message.service.ts
import { AxiosRequestConfig } from "axios";
import { ApiResponse, ApiProductResponse, ApiProductsResponse, SettingResponse } from "../models/api-response";
import { callExternalApi } from "./external-api.service";
import { AppError } from "../models/app-error";

export const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getPublicResource = async (): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/messages/public`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getProtectedResource = async (
  accessToken: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/messages/protected`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getSubscribedProducts = async (
  accessToken: string
): Promise<ApiProductsResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/stripe/products/subscriptions`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiProductsResponse;

  return {
    data,
    error,
  };
};

export const getPlans = async (): Promise<ApiProductsResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/stripe/products`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiProductsResponse;

  return {
    data,
    error,
  };
};

export const getProductsResource = async (
  accessToken: string
): Promise<ApiProductsResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/stripe/products`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiProductsResponse;

  return {
    data,
    error,
  };
};

export const getProductResource = async (
  accessToken: string,
  product_id: string
): Promise<ApiProductResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/stripe/products/${product_id}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiProductResponse;

  return {
    data,
    error,
  };
};

export const getAdminResource = async (
  accessToken: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/messages/admin`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config }) as ApiResponse;

  return {
    data,
    error,
  };
};

interface CheckoutSessionResponse {
  data: {
    url: string
  }
  error: AppError | null;
}

export const createCheckoutSession = async (
  accessToken: string | null,
  product_id: string
): Promise<CheckoutSessionResponse> => {
  const currentUrl = encodeURIComponent(window.location.href);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/stripe/checkout/sessions/new?product_id=${product_id}&current_url=${currentUrl}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  const response = await callExternalApi({ config });

  const { data, error } = response as unknown as CheckoutSessionResponse;

  return {
    data,
    error,
  };
};

export const cancelSubscription = async (
  accessToken: string | null
): Promise<{ error?: string }> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/stripe/subscriptions/cancel_all`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  const { error } = await callExternalApi({ config });

  return {
    error: error ? error.toString() : undefined,
  };
};

export const createPersonalAccessToken = async (
  accessToken: string | null
): Promise<SettingResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/personal_access_tokens`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  const { data, error } = await callExternalApi({ config }) as unknown as SettingResponse;

  return {
    data,
    error,
  };
};

export const fetchPersonalAccessTokens = async (
  accessToken: string | null
): Promise<SettingResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/personal_access_tokens`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  const { data, error } = await callExternalApi({ config }) as unknown as SettingResponse;

  return {
    data,
    error,
  };
};

export const deletePersonalAccessToken = async (
  accessToken: string | null,
  personalAccessTokenId: number | null
): Promise<SettingResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/personal_access_tokens/${personalAccessTokenId}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  const { data, error } = await callExternalApi({ config }) as unknown as SettingResponse;

  return {
    data,
    error,
  };
};

export const fetchSettings = async (
  accessToken: string | null
): Promise<SettingResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/auth0/settings`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  const { data, error } = await callExternalApi({ config }) as unknown as SettingResponse;

  return {
    data,
    error,
  };
}
