import { PageLoader } from "./page-loader";

const NehPageLoader: React.FC = () => {
  return (
    <div className="page-layout">
      <PageLoader />
    </div>
  );
};

export default NehPageLoader;
