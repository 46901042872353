// src/components/navigation/mobile/mobile-nav-bar-tabs.tsx
import { useAuth0 } from "@auth0/auth0-react";

import { useSettings } from "../../page-layout";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";

interface MobileNavBarTabsProps {
  handleClick: () => void;
}

export const MobileNavBarTabs: React.FC<MobileNavBarTabsProps> = ({
  handleClick,
}) => {
  const { isAuthenticated } = useAuth0();
  const settings = useSettings();

  return (
    <div className="mobile-nav-bar__tabs">
      <MobileNavBarTab path="/plans" label="Plans" handleClick={handleClick} />

      {
        isAuthenticated && (
          <>
            {
              settings?.is_admin &&
                <MobileNavBarTab path="/admin" label="Admin" handleClick={handleClick} />
            }

            <MobileNavBarTab path="/settings" label="Settings" handleClick={handleClick} />
          </>
        )
      }
    </div>
  );
};
