import { useEffect, useState } from "react";

import { getProductResource } from "../services/message.service";
import { Product } from "../models/product";

export const useProduct = (auth0AccessToken: string | null, product_id: string | undefined) => {
  const [product, setProduct] = useState<Product>();

  useEffect(() => {
    const fetchProduct = async () => {
      if (!auth0AccessToken) return;
      if (!product_id) return;

      const { data: product, error } = await getProductResource(auth0AccessToken, product_id);

      if (product) setProduct(product);
      if (error) console.log({ error });
    };

    fetchProduct();
  }, [auth0AccessToken, product_id]);

  return product;
};
