import React from "react";
import { NavLink } from "react-router-dom";

const logo = "https://raw.githubusercontent.com/neh-cli/neh/main/logo/logo_white.png";

export const NavBarBrand: React.FC = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="neh-nav-bar__neh_logo"
          src={logo}
          alt="Neh logo"
        />
      </NavLink>
    </div>
  );
};
