import React from "react";
import { PageLayout } from "../components/page-layout";

export const NotFoundPage: React.FC = () => {
  return (
    <PageLayout>
      <div className="auth0-features">
        <h1 className="neh-features__title">
          Not Found
        </h1>
      </div>
    </PageLayout>
  );
};
