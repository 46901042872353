import { PageLayout } from "../components/page-layout";

export const actNotices = {
  // https://support.stripe.com/questions/how-to-create-and-display-a-commerce-disclosure-page?locale=en-US
  en: {
    title: "Commerce Disclosure",
    body: [
      {
        key: "Legal Name",
        value: "Atsushi Ishida"
      },
      {
        key: "Address",
        value: "4F, Kabutocho Dai 6 Hayama Building, 17-2, Nihonbashi Kabutocho, Chuo-ku, Tokyo 103-0026, Japan"
      },
      {
        key: "Email address",
        value: "support [at-mark] neh.app(Replace [at-mark] with @.)",
      },
      {
        key: "Phone number",
        value: "Available upon request, but inquiries by phone are generally not accepted. Please contact us via the email address above."
      },
      {
        key: "Head of Operations",
        value: "Atsushi Ishida",
      },
      {
        key: "Additional fees",
        value: "Additional fees are to be borne by the customer."
      },
      {
        key: "Exchanges & Returns Policy",
        value: "No exchanges or returns are accepted for products provided by this service, regardless of the reason. However, a Free Plan is available for consideration before purchasing a paid Plan."
      },
      {
        key: "Delivery times",
        value: "Unless otherwise specified on the purchase page, the service can be used immediately upon confirmation of payment and purchase verification on Stripe, which is the service's affiliated platform."
      },
      {
        key: "Accepted payment methods",
        value: "Credit card"
      },
      {
        key: "Payment period",
        value: "In accordance with the credit card company's regulations."
      },
      {
        key: "Price",
        value: "As stated for each Plan."
      }
    ]
  },

  // https://support.stripe.com/questions/how-to-create-and-display-a-commerce-disclosure-page?locale=ja-JP
  ja: {
    title: "特定商取引法に基づく表記",
    body: [
      {
        key: "販売業社の名称",
        value: "石田敦志"
      },
      {
        key: "所在地",
        value: "〒103-0026 中央区日本橋兜町 17-2 兜町第 6 葉山ビル 4F"
      },
      {
        key: "メールアドレス",
        value: "ｓｕｐｐｏｒｔ［ａｔ-ｍａｒｋ］ｎｅｈ.ａｐｐ<br>但し、［ａｔ-ｍａｒｋ］は@へ、全角アルファベットは半角アルファベットへ置換してください。"
      },
      {
        key: "電話番号",
        value: "請求時開示可能ですが、原則、電話での問い合わせは受け付けておりません。 <br>お問い合わせ、ご質問等は上記メールアドレスよりお願い致します。"
      },
      {
        key: "運営統括責任者",
        value: "石田敦志",
      },
      {
        key: "追加手数料等の追加料金",
        value: "追加手数料等の追加料金はお客様のご負担となります。"
      },
      {
        key: "交換および返品",
        value: "本サービスが提供する商品については、いかなる理由においても交換・返品はお受けしておりません。<br>無料で利用可能なFree Planをご用意しておりますので、こちらで十分にご検討の上、有料Planをご購入ください。",
      },
      {
        key: "引渡時期",
        value: "購入に関するページに特別な定めを置いている場合を除き、代金決済手続きの完了確認、及び、本サービスの連携先となるStripe上での購入確認が取れ次第、直ちにご利用いただけます。"
      },
      {
        key: "受け付け可能な決済手段",
        value: "クレジットカード"
      },
      {
        key: "決済期間",
        value: "クレジットカード会社の規定に準拠します。"
      },
      {
        key: "販売価格",
        value: "各Planに記載の通りです。"
      }
    ]
  }
}

export const ActPage: React.FC = () => {
  const legalNotice = actNotices["en"];
  const width = '180px';

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          {legalNotice.title}
        </h1>

        <div className="content__body">
          {legalNotice.body.map((item, index) => (
            <div key={index} className="legal-notice__item" style={{ display: 'flex', alignItems: 'flex-start' }}>
              <strong style={{ marginRight: '10px', minWidth: width, maxWidth: width }}>{item.key}</strong>
              <span dangerouslySetInnerHTML={{ __html: item.value }} />
            </div>
          ))}
        </div>
      </div>
    </PageLayout>
  );
};
