// src/components/navigation/desktop/nav-bar-tabs.tsx
import { useAuth0 } from "@auth0/auth0-react";

import { useSettings } from "../../page-layout";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarTabs: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const settings = useSettings();

  return (
    <div className="nav-bar__tabs">
      <NavBarTab path="/plans" label="Plans" />

      {
        isAuthenticated && (
          <>
            {
              settings?.is_admin &&
                <NavBarTab path="/admin" label="Admin" />
            }

            <NavBarTab path="/settings" label="Settings" />
          </>
        )
      }
    </div>
  );
};
