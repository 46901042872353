import { Link } from "react-router-dom";

interface PlanProps {
  product: any;
}

const formatPrice = (price: string, language: string): string => {
  return language === 'ja' ? Number(price).toLocaleString('ja-JP') : Number(price).toLocaleString('en-US');
}

const language = 'en';

const Plan: React.FC<PlanProps> = ({ product }) => {
  const price = product.prices.find((price: any) => price.language === language);

  return (
    <Link
      to={`/plans/${product.product_id}`}
      className="auth0-feature"
    >
      <h3 className="auth0-feature__headline">
        <img
          className="auth0-feature__icon"
          src={`/icons/${product.plan_code}.svg`}
          alt="external link icon"
        />
        {product.name}
      </h3>


      <span className="neh-plan-price">
        {price.unit_symbol}{formatPrice(price.price_on_page, language)}
      </span>

      <span className="neh-plan-billing_cycle">
        {' / '} {price.montyly_billing_cycle}
      </span>

      <span className="margin-insert" />

      <p className="auth0-feature__description">{product.description}</p>
    </Link>
  )
};

export default Plan;
