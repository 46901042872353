import React from "react";
import { Link } from "react-router-dom";

export const HeroBanner: React.FC = () => {
  const logo = "https://raw.githubusercontent.com/neh-cli/neh/main/logo/logo_black.png";

  return (
    <div className="hero-banner">
      <div className="hero-banner__logo">
        <img className="hero-banner__image" src={logo} alt="Neh Logo" />
      </div>

      <h1 className="hero-banner__headline">Neh accelerates your daily tasks</h1>

      <p className="hero-banner__description">
        Neh is a sleek CLI application designed for high-speed, real-time interaction with AI specializing in Large Language Models.
      </p>

      <Link
        id="code-sample-link"
        to="/plans"
        className="button button--secondary button--outline-black"
      >
        Go to Plans
      </Link>
    </div>
  );
};
