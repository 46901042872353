import React from "react";
// import { Auth0Features } from "src/components/auth0-features";
import { HeroBanner } from "src/components/hero-banner";
import { PageLayoutNeh } from "../components/page-layout-neh";

export const HomePage: React.FC = () => (
  <PageLayoutNeh>
    <>
      <HeroBanner />

      {/* ここに 動画やプロモーションを掲載する */}
      {/* <Auth0Features /> */}
    </>
  </PageLayoutNeh>
);
