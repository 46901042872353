// src/components/page-layout.tsx
import React, { useEffect, useState, createContext, useContext } from "react";

import { useAuthToken } from "../hooks/useAuthToken";
import { NavBar } from "./navigation/desktop/nav-bar";
import { MobileNavBar } from "./navigation/mobile/mobile-nav-bar";
import { PageFooter } from "./page-footer";
import { fetchSettings } from "../services/message.service";

interface Props {
  children: JSX.Element;
}

const SettingsContext = createContext<any>(null);

export const useSettings = () => {
  return useContext(SettingsContext);
}

export const PageLayoutNeh: React.FC<Props> = ({ children }) => {
  const auth0AccessToken = useAuthToken();
  const [settings, setSettings] = useState<any>({});

  useEffect(() => {
    const fetchSettingsData = async () => {
      const { data, error } = await fetchSettings(auth0AccessToken);
      const is_admin = data.is_admin;
      settings.is_admin = is_admin;
      setSettings(settings);

      if (data) {
        setSettings(data);
      }

      if (error) console.error({ error });
    };

    if (auth0AccessToken) fetchSettingsData();
  }, [auth0AccessToken]);

  return (
    <SettingsContext.Provider value={settings}>
      <div className="page-layout">
        <NavBar />
        <MobileNavBar />
        <div className="neh_page-layout__content">{children}</div>
        <PageFooter />
      </div>
    </SettingsContext.Provider>
  );
};
