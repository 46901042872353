import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";

export const useAuthToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [auth0AccessToken, setAuth0AccessToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchAuthToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setAuth0AccessToken(accessToken);
      } catch (error) {
      }
    };

    fetchAuthToken();
  }, [getAccessTokenSilently]);

  return auth0AccessToken;
};
